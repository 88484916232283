import ProccessDownload from './../proccessDownload/ProccessDownload'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function Rotas() {
  return (
    <Router>
      <Routes>  
        <Route path='/download/:parametro' element={<ProccessDownload/>} />
      </Routes>
    </Router>
  );
}

export default Rotas;